@import '../variable';

.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 40px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 17px 19px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    width: 67px !important;
    height: 67px !important;
    z-index: 9999999999;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.1);
  }

  .whatsapp-icon i.fa.fa-whatsapp {
    font-size: 35px;
    color: white;
}
  